import CallToAction from "components/CallToAction"
import getStoryblokLink from "utilities/getStoryblokLink"

export default function LastChanceCTA({ blok }) {
  return (
    <section className="bg-green-apple py-6 px-4 text-center xl:flex justify-center gap-6">
      <p className="text-m3 lg:text-l1 text-black font-bold pb-4 xl:pb-0">{blok.cta}</p>
      <div className="flex flex-col md:flex-row gap-4 justify-center">
        <CallToAction
          href={getStoryblokLink(blok.primary_cta_link)}
          style="secondary"
          className="table md:block mx-auto md:mx-0 border-white text-black"
        >
          {blok.primary_cta_label}
        </CallToAction>
        {blok.secondary_cta_label && blok.secondary_cta_link ? (
          <CallToAction
            href={getStoryblokLink(blok.secondary_cta_link)}
            style="quaternary"
            className="table md:block mx-auto md:mx-0"
          >
            {blok.secondary_cta_label}
          </CallToAction>
        ) : null}
      </div>
    </section>
  )
}
